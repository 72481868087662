@import '~assets/styles/variables';

.static-list {
  &__wrapper {
    position: relative;
  }
  &__empty {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color--background--light;
  }
  &__ul {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100%;
  }
  &__overlay {
    display: none;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color--background;
    opacity: 0.5;
    &--active {
      display: block;
    }
  }
}
